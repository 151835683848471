import React, { useState, useEffect } from 'react';
import { COUNTRIES } from '../../utils/countries';

export const UserRegistration = ({ onRegister }) => {
    const [formData, setFormData] = useState({
        username: '',
        age: '',
        country: '',
        termsAccepted: false
    });
    const [showTerms, setShowTerms] = useState(false);
    const [usernameStatus, setUsernameStatus] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [currentBgIndex, setCurrentBgIndex] = useState(0);

    // Define background patterns
    const backgrounds = [
        // Ocean theme
        'bg-gradient-to-br from-blue-400 via-cyan-400 to-teal-400',
        // Red flowers theme
        'bg-gradient-to-br from-red-400 via-pink-400 to-rose-400',
        // Purple range theme
        'bg-gradient-to-br from-purple-400 via-violet-400 to-indigo-400',
        // Sunset theme
        'bg-gradient-to-br from-orange-400 via-amber-400 to-yellow-400'
    ];

    // Background rotation effect
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentBgIndex((prevIndex) =>
                prevIndex === backgrounds.length - 1 ? 0 : prevIndex + 1
            );
        }, 15000); // Change every 15 seconds

        return () => clearInterval(intervalId);
    }, []);

    // Rest of your existing functions
    const generateUsernameSuggestions = (base) => {
        if (!base) return [];
        return [
            `${base}${Math.floor(Math.random() * 999)}`,
            `${base}_${Math.floor(Math.random() * 999)}`,
            `awesome_${base}`,
            `${base}_chat`,
            `${base}${new Date().getFullYear()}`
        ];
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, username: value });

        if (value.length < 3) {
            setUsernameStatus('Username must be at least 3 characters');
            setSuggestions([]);
        } else if (value.length > 20) {
            setUsernameStatus('Username must be less than 20 characters');
            setSuggestions([]);
        } else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
            setUsernameStatus('Only letters, numbers, and underscores allowed');
            setSuggestions([]);
        } else {
            // Simulate checking if username is taken
            const isTaken = Math.random() < 0.5; // Random simulation
            if (isTaken) {
                setUsernameStatus('Username already taken');
                setSuggestions(generateUsernameSuggestions(value));
            } else {
                setUsernameStatus('Username available');
                setSuggestions([]);
            }
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setFormData({ ...formData, username: suggestion });
        setUsernameStatus('Username available');
        setSuggestions([]);
    };

    const validateForm = () => {
        if (!formData.username.trim() || usernameStatus !== 'Username available') return false;
        if (!formData.age || parseInt(formData.age) < 18) return false;
        if (!formData.country) return false;
        if (!formData.termsAccepted) return false;
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm() && onRegister) {
            try {
                await onRegister(formData);
                // The parent component will handle navigation
            } catch (error) {
                console.error('Registration failed:', error);
            }
        }
    };

    return (
        // Apply dynamic background class
        <div className={`min-h-screen py-12 px-4 sm:px-6 lg:px-8 transition-colors duration-1000 ${backgrounds[currentBgIndex]}`}>
            <div className="max-w-md mx-auto">
                <div className="text-center mb-8">
                    <div className="inline-block p-3 rounded-full bg-blue-600 mb-4">
                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                        </svg>
                    </div>
                    <h1 className="text-4xl font-bold text-white mb-2 text-shadow">ChatNexus</h1>
                    <p className="text-white text-shadow">Connect with people around the world</p>
                </div>

                <div className="bg-white/95 backdrop-blur-sm rounded-xl shadow-lg p-8">
                    {/* Rest of your form JSX stays exactly the same */}
                    <h2 className="text-2xl font-semibold text-gray-800 mb-6">Join the Conversation</h2>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Username field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Username
                            </label>
                            <input
                                type="text"
                                value={formData.username}
                                onChange={handleUsernameChange}
                                placeholder="Choose a username"
                                className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                                    usernameStatus === 'Username available'
                                        ? 'border-green-500'
                                        : usernameStatus
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                }`}
                            />
                            {usernameStatus && (
                                <p className={`mt-1 text-sm ${
                                    usernameStatus === 'Username available'
                                        ? 'text-green-600'
                                        : 'text-red-600'
                                }`}>
                                    {usernameStatus}
                                </p>
                            )}
                            {suggestions.length > 0 && (
                                <div className="mt-2 space-y-2">
                                    <p className="text-sm text-gray-600">Suggested usernames:</p>
                                    <div className="flex flex-wrap gap-2">
                                        {suggestions.map((suggestion, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                onClick={() => handleSuggestionClick(suggestion)}
                                                className="px-3 py-1 text-sm bg-blue-50 text-blue-600 rounded-full hover:bg-blue-100"
                                            >
                                                {suggestion}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Age field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Age
                            </label>
                            <input
                                type="number"
                                value={formData.age}
                                onChange={(e) => setFormData({ ...formData, age: e.target.value })}
                                placeholder="Enter your age"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        {/* Country field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Country
                            </label>
                            <select
                                value={formData.country}
                                onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                            >
                                <option value="">Select your country</option>
                                {COUNTRIES.map(country => (
                                    <option key={country.code} value={country.code}>
                                        {country.flag} {country.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Terms checkbox */}
                        <div className="flex items-center bg-blue-50 p-3 rounded-lg">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={formData.termsAccepted}
                                onChange={(e) => setFormData({ ...formData, termsAccepted: e.target.checked })}
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
                                I accept the{' '}
                                <button
                                    type="button"
                                    onClick={() => setShowTerms(true)}
                                    className="text-blue-600 underline hover:text-blue-800"
                                >
                                    terms and conditions
                                </button>
                            </label>
                        </div>

                        {/* Submit button */}
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white font-medium py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-200 disabled:opacity-50"
                            disabled={!validateForm()}
                        >
                            Start Chatting
                        </button>
                    </form>
                </div>
            </div>

            {/* Terms modal */}
            {/* Terms modal */}
            {showTerms && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-xl max-w-4xl w-full max-h-[80vh] overflow-hidden">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold text-gray-900 mb-4">Terms and Conditions</h3>
                            <div className="overflow-y-auto max-h-[60vh] pr-4 mb-4">
                                <p className="font-medium mb-4">By using ChatNexus, you agree to abide by the following terms and conditions:</p>

                                <section className="mb-6">
                                    <h4 className="text-lg font-semibold text-gray-800 mb-2">General Requirements</h4>
                                    <ul className="list-disc pl-5 space-y-2 text-gray-600">
                                        <li>You must be at least 18 years old to use this platform.</li>
                                        <li>You are responsible for maintaining the confidentiality of your account.</li>
                                    </ul>
                                </section>

                                <section className="mb-6">
                                    <h4 className="text-lg font-semibold text-gray-800 mb-2">Content Rules</h4>
                                    <ul className="list-disc pl-5 space-y-2 text-gray-600">
                                        <li>No illegal or harmful content</li>
                                        <li>No hate speech or harassment</li>
                                        <li>No sharing of personal information</li>
                                        <li>No spamming or disruptive behavior</li>
                                    </ul>
                                </section>

                                <section className="mb-6">
                                    <h4 className="text-lg font-semibold text-gray-800 mb-2">Privacy & Security</h4>
                                    <ul className="list-disc pl-5 space-y-2 text-gray-600">
                                        <li>We protect your personal information</li>
                                        <li>You control your data and privacy settings</li>
                                        <li>Report any suspicious activities</li>
                                    </ul>
                                </section>
                            </div>
                            <div className="flex justify-end border-t pt-4">
                                <button
                                    onClick={() => {
                                        setShowTerms(false);
                                        setFormData(prev => ({ ...prev, termsAccepted: true }));
                                    }}
                                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                                >
                                    Accept & Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};