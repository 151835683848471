import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { Hash } from 'lucide-react';
import MessageList from "./MessageList";
import { ChatInput } from "./ChatInput";
import { RoomList } from "./RoomList";
import { UserSidebar } from "./UserSidebar";

export const ChatInterface = ({ user, settings }) => {


    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [messageHistory, setMessageHistory] = useState({
        rooms: {
            General: [],
            Gaming: [],
            Music: []
        },
        privateChats: new Map()
    });
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [currentRoom, setCurrentRoom] = useState('General');
    const [selectedUser, setSelectedUser] = useState(null);
    const [privateMessage, setPrivateMessage] = useState('');
    //const [privateChats, setPrivateChats] = useState(new Map());
    const [unreadMessages, setUnreadMessages] = useState({});
    const [expandedSections, setExpandedSections] = useState({
        rooms: true,
        directMessages: true
    });
    const [joinedRooms] = useState(new Set(['General']));
    const socket = useRef(null);
    const messagesEndRef = useRef(null);
    const rooms = ['General', 'Gaming', 'Music'];

    useEffect(() => {
        // Timeout for 5 minutes
        const timer = setTimeout(() => {
            localStorage.removeItem('user');
            window.location.reload();
        }, 300000); // 5 minutes

        // Handle page refresh/close
        const handleBeforeUnload = () => {
            localStorage.removeItem('user');
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup
        return () => {
            clearTimeout(timer);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        socket.current = io(process.env.REACT_APP_SERVER_URL);

        // Join initial room
        socket.current.emit('join', { username: user.username, room: currentRoom });

        // Handle room messages
        socket.current.on('message', (message) => {
            if (message.type === 'system' &&
                message.text.includes('has joined the chat') &&
                joinedRooms.has(message.room)) {
                return;
            }
            setMessageHistory(prev => ({
                ...prev,
                rooms: {
                    ...prev.rooms,
                    [message.room]: [...(prev.rooms[message.room] || []), message]
                }
            }));
        });

        // Handle private messages
        socket.current.on('privateMessage', (message) => {
            const chatId = getChatId(message.from, message.to);

            // Add both users to active users

            setMessageHistory(prev => {
                const updatedPrivateChats = new Map(prev.privateChats);
                const existing = updatedPrivateChats.get(chatId) || [];
                updatedPrivateChats.set(chatId, [...existing, message]);

                return {
                    ...prev,
                    privateChats: updatedPrivateChats
                };
            });

            // Update unread messages count only if message is from someone else
            if (message.from !== user.username) {
                setUnreadMessages(prev => ({
                    ...prev,
                    [message.from]: (prev[message.from] || 0) + 1
                }));
            }
        });

        // Handle online users update
        socket.current.on('updateUsers', (users) => {
            setOnlineUsers(users.filter(u => u !== user.username));
        });

        return () => {
            socket.current.disconnect();
        };
    }, [user.username, currentRoom]);

    const handleRoomChange = (newRoom) => {
        if (currentRoom !== newRoom) {
            if (!joinedRooms.has(newRoom)) {
                socket.current.emit('changeRoom', {
                    username: user.username,
                    newRoom,
                    oldRoom: currentRoom
                });
                joinedRooms.add(newRoom);
            } else {
                socket.current.emit('switchRoom', {
                    username: user.username,
                    newRoom,
                    oldRoom: currentRoom
                });
            }
            setCurrentRoom(newRoom);
            setSelectedUser(null);
        }
    };

    const handleUserSelect = (selectedUsername) => {
        setSelectedUser(selectedUsername);
        setCurrentRoom(null);
        setUnreadMessages(prev => ({
            ...prev,
            [selectedUsername]: 0
        }));
    };

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));

    };

    const handleSendMessage = (e, formattedMessage) => {
        e.preventDefault();

        if (formattedMessage.text.trim()) {
            if (selectedUser) {
                // Handle private message
                const messageData = {
                    text: formattedMessage.text,
                    from: user.username,
                    to: selectedUser,
                    time: new Date().toLocaleTimeString(),
                    timestamp: Date.now(),
                    isPrivate: true,
                    isBold: formattedMessage.isBold,
                    isItalic: formattedMessage.isItalic
                };

                socket.current.emit('privateMessage', messageData);

                // Don't update local state immediately - wait for server confirmation
            } else {
                // Handle room message
                const messageData = {
                    text: formattedMessage.text,
                    sender: user.username,
                    room: currentRoom,
                    time: new Date().toLocaleTimeString(),
                    timestamp: Date.now(),
                    isBold: formattedMessage.isBold,
                    isItalic: formattedMessage.isItalic
                };

                socket.current.emit('message', messageData);
                // Don't update local state immediately - wait for server confirmation
            }

            setTimeout(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
            // Clear input after sending
            setMessage('');
            setPrivateMessage('');
        }
    };

    const getChatId = (user1, user2) => {
        return [user1, user2].sort().join('-');
    };



    const getCurrentMessages = () => {
        if (selectedUser) {
            const chatId = getChatId(user.username, selectedUser);
            return messageHistory.privateChats.get(chatId) || [];
        }
        return messageHistory.rooms[currentRoom] || [];
    };

    // Get active users who have had conversations
    const getActiveDirectMessageUsers = () => {
        // Get all unique users from private chat history
        const activeUserSet = new Set();
        messageHistory.privateChats.forEach((messages, chatId) => {
            const [user1, user2] = chatId.split('-');
            if (user1 !== user.username) activeUserSet.add(user1);
            if (user2 !== user.username) activeUserSet.add(user2);
        });

        // Only return users who are currently online
        return onlineUsers.filter(username => activeUserSet.has(username));
    };



    return (
        <div className="flex w-full h-screen">
            <RoomList
                rooms={rooms}
                currentRoom={currentRoom}
                expandedSections={expandedSections}
                onRoomChange={handleRoomChange}
                onToggleSection={toggleSection}
                onlineUsers={getActiveDirectMessageUsers()}
                selectedUser={selectedUser}
                unreadMessages={unreadMessages}
                onUserSelect={handleUserSelect}
                username={user.username}
            />

            <div className="flex-1 flex flex-col bg-white overflow-hidden">
                <div className="p-4 border-b bg-white shadow-sm">
                    <h2 className="font-bold text-lg flex items-center">
                        {selectedUser ? (
                            <>
                                <div className="w-2 h-2 bg-green-500 rounded-full mr-2"/>
                                @{selectedUser}
                            </>
                        ) : (
                            <>
                                <Hash className="w-5 h-5 mr-2"/>
                                {currentRoom}
                            </>
                        )}
                    </h2>
                </div>

                <MessageList
                    messages={getCurrentMessages()}
                    username={user.username}
                    typingUsers={new Set()}
                    messagesEndRef={messagesEndRef}
                />

                <ChatInput
                    message={selectedUser ? privateMessage : message}
                    onMessageChange={e => selectedUser ? setPrivateMessage(e.target.value) : setMessage(e.target.value)}
                    onSendMessage={handleSendMessage}
                    placeholder={`Message ${selectedUser ? '@' + selectedUser : '#' + currentRoom}`}
                />
            </div>

            <UserSidebar
                onlineUsers={onlineUsers}
                onUserSelect={handleUserSelect}
            />
        </div>
    );
};

export default ChatInterface;