// src/components/chat/RoomList.js
import React from 'react';
import { MessageCircle, ChevronDown, ChevronRight, Hash } from 'lucide-react';

export const RoomList = ({
                             rooms,
                             currentRoom,
                             expandedSections,
                             onRoomChange,
                             onToggleSection,
                             onlineUsers,
                             selectedUser,
                             unreadMessages,
                             onUserSelect,
                             username
                         }) => {
    return (
        <div className="w-64 bg-gray-800 text-gray-100">
            {/* Header */}
            <div className="p-4 border-b border-gray-700">
                <h1 className="text-xl font-bold">ChatApp</h1>
                <p className="text-sm text-gray-400">Welcome, {username}</p>
            </div>

            {/* Rooms Section */}
            <div className="p-2">
                <div
                    className="flex items-center p-2 cursor-pointer hover:bg-gray-700 rounded"
                    onClick={() => onToggleSection('rooms')}
                >
                    {expandedSections.rooms ?
                        <ChevronDown className="w-4 h-4 mr-2" /> :
                        <ChevronRight className="w-4 h-4 mr-2" />
                    }
                    <Hash className="w-4 h-4 mr-2" />
                    <span className="font-medium">Channels</span>
                </div>

                {expandedSections.rooms && (
                    <div className="space-y-1">
                        {rooms.map(room => (
                            <div
                                key={room}
                                onClick={() => onRoomChange(room)}
                                className={`pl-8 p-2 cursor-pointer rounded ${
                                    currentRoom === room && !selectedUser
                                        ? 'bg-blue-600 text-white'
                                        : 'hover:bg-gray-700'
                                }`}
                            >
                                # {room}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Direct Messages Section */}
            <div className="p-2">
                <div
                    className="flex items-center p-2 cursor-pointer hover:bg-gray-700 rounded"
                    onClick={() => onToggleSection('directMessages')}
                >
                    {expandedSections.directMessages ?
                        <ChevronDown className="w-4 h-4 mr-2" /> :
                        <ChevronRight className="w-4 h-4 mr-2" />
                    }
                    <MessageCircle className="w-4 h-4 mr-2" />
                    <span className="font-medium">Direct Messages</span>
                </div>

                {expandedSections.directMessages && (
                    <div className="space-y-1">
                        {onlineUsers.map(user => (
                            <div
                                key={user}
                                className={`pl-8 p-2 cursor-pointer rounded ${
                                    selectedUser === user ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
                                }`}
                                onClick={() => onUserSelect(user)}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="w-2 h-2 bg-green-500 rounded-full mr-2" />
                                        <span>{user}</span>
                                    </div>
                                    {unreadMessages[user] > 0 && (
                                        <span className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
                                            {unreadMessages[user]}
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Optional: Add a user settings or logout section */}
            <div className="absolute bottom-0 w-64 p-4 border-t border-gray-700">
                <div className="flex items-center space-x-2">
                    <div className="w-2 h-2 bg-green-500 rounded-full" />
                    <span className="text-sm">{username} (You)</span>
                </div>
            </div>
        </div>
    );
};