import React, { useState, useCallback } from 'react';
import { Bold, Italic, Type, Palette, Smile } from 'lucide-react';
import EmojiPicker from 'emoji-picker-react';

const COLORS = [
    { name: 'red', class: 'text-red-600' },
    { name: 'blue', class: 'text-blue-600' },
    { name: 'green', class: 'text-green-600' },
    { name: 'purple', class: 'text-purple-600' },
    { name: 'yellow', class: 'text-yellow-600' },
    { name: 'pink', class: 'text-pink-600' },
    { name: 'indigo', class: 'text-indigo-600' },
    { name: 'teal', class: 'text-teal-600' },
    { name: 'orange', class: 'text-orange-600' },
    { name: 'cyan', class: 'text-cyan-600' }
];

export const ChatInput = ({
                              message,
                              onMessageChange,
                              onSendMessage,
                              showMentions,
                              mentionsList,
                              onMentionSelect,
                              placeholder
                          }) => {
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

    const handleEmojiClick = useCallback((emojiData) => {
        const emoji = emojiData.emoji;
        onMessageChange({ target: { value: message + emoji } });
        setIsEmojiPickerVisible(false);
    }, [message, onMessageChange]);

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    }, [message]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim()) {
            onSendMessage(e, {
                text: message,
                isBold,
                isItalic,
                color: selectedColor,
                mentions: message.match(/@(\w+)/g)?.map(m => m.slice(1)) || [],
                time: new Date().toLocaleTimeString()
            });

            // Reset formatting after sending
            setIsBold(false);
            setIsItalic(false);
            setSelectedColor(null);
        }
    };

    const closeAllPickers = () => {
        setIsEmojiPickerVisible(false);
        setShowColorPicker(false);
    };

    return (
        <div className="border-t p-4 bg-white">
            <div className="flex items-center space-x-2 mb-2">
                <button
                    type="button"
                    onClick={() => setIsBold(!isBold)}
                    className={`p-2 rounded ${isBold ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                    title="Bold"
                >
                    <Bold className="w-4 h-4" />
                </button>
                <button
                    type="button"
                    onClick={() => setIsItalic(!isItalic)}
                    className={`p-2 rounded ${isItalic ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                    title="Italic"
                >
                    <Italic className="w-4 h-4" />
                </button>
                <div className="relative">
                    <button
                        type="button"
                        onClick={() => {
                            setShowColorPicker(!showColorPicker);
                            setIsEmojiPickerVisible(false);
                        }}
                        className="p-2 rounded hover:bg-gray-100"
                        title="Text Color"
                    >
                        <Palette className="w-4 h-4" />
                    </button>
                    {showColorPicker && (
                        <div className="absolute bottom-full left-0 bg-white shadow-lg rounded-lg p-2 mb-2 z-50">
                            <div className="grid grid-cols-5 gap-1 p-2">
                                {COLORS.map(color => (
                                    <button
                                        key={color.name}
                                        type="button"
                                        title={color.name}
                                        className={`w-6 h-6 rounded-full bg-${color.name}-500 ${
                                            selectedColor === color.name ? 'ring-2 ring-offset-2 ring-blue-500' : ''
                                        } hover:scale-110 transition-transform`}
                                        onClick={() => {
                                            setSelectedColor(color.name);
                                            setShowColorPicker(false);
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="relative">
                    <button
                        type="button"
                        onClick={() => {
                            setIsEmojiPickerVisible(!isEmojiPickerVisible);
                            setShowColorPicker(false);
                        }}
                        className="p-2 rounded hover:bg-gray-100"
                        title="Emoji"
                    >
                        <Smile className="w-4 h-4" />
                    </button>
                    {isEmojiPickerVisible && (
                        <div
                            className="absolute bottom-full right-0 mb-2 z-50"
                            onClick={e => e.stopPropagation()}
                            style={{
                                position: 'fixed',  // Changed from absolute to fixed
                                bottom: '100px',    // Added specific bottom position
                                right: '20px',      // Added specific right position
                            }}
                        >
                            <EmojiPicker
                                onEmojiClick={handleEmojiClick}
                                width={300}
                                height={400}
                            />
                        </div>
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="relative">
                <textarea
                    value={message}
                    onChange={(e) => onMessageChange(e)}
                    onKeyPress={handleKeyPress}
                    placeholder={placeholder}
                    className={`w-full p-3 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                        isBold ? 'font-bold' : ''
                    } ${isItalic ? 'italic' : ''} ${
                        selectedColor ? `text-${selectedColor}-600` : ''
                    }`}
                    rows={2}
                />

                {showMentions && mentionsList.length > 0 && (
                    <div className="absolute bottom-full left-0 bg-white shadow-lg rounded-lg p-2 mb-2 max-h-48 overflow-y-auto z-50">
                        {mentionsList.map((user, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => onMentionSelect(user)}
                            >
                                @{user}
                            </div>
                        ))}
                    </div>
                )}

                <div className="flex justify-end mt-2">
                    <button
                        type="submit"
                        className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={!message.trim()}
                    >
                        Send
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChatInput;