import React, { useState, useEffect } from 'react';
import { Bold, Italic, Image, Type } from 'lucide-react';

const WALLPAPERS = [
    { id: 'default', url: 'none', name: 'Default', class: 'bg-white' },
    { id: 'gradient1', class: 'bg-gradient-to-r from-blue-50 to-purple-50', name: 'Cool Gradient' },
    { id: 'gradient2', class: 'bg-gradient-to-r from-green-50 to-blue-50', name: 'Nature Gradient' },
    { id: 'dots', class: 'bg-gray-50', name: 'Dotted' },
    { id: 'lines', class: 'bg-gray-50', name: 'Lined' }
];

export const MessageList = ({ messages, username, typingUsers, messagesEndRef }) => {
    const [wallpaper, setWallpaper] = useState('default');
    const [showWallpapers, setShowWallpapers] = useState(false);

    useEffect(() => {
        console.log('Messages received in MessageList:', messages);
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const getMessageStyles = (message) => {
        const isOwnMessage = message.from === username || message.sender === username;
        const isPrivateMessage = message.isPrivate || message.from;

        let baseStyles = `p-3 rounded-lg max-w-[80%] break-words `;

        if (isPrivateMessage) {
            baseStyles += isOwnMessage
                ? 'ml-auto bg-indigo-600 text-white'
                : 'bg-indigo-50 text-gray-900';
        } else {
            baseStyles += isOwnMessage
                ? 'ml-auto bg-blue-600 text-white'
                : 'bg-white shadow-sm text-gray-900';
        }

        const formattingStyles = [
            message.isBold ? 'font-bold' : '',
            message.isItalic ? 'italic' : '',
            (!isOwnMessage && message.color) ? `text-${message.color}-600` : ''
        ].filter(Boolean).join(' ');

        return `${baseStyles} ${formattingStyles}`;
    };

    const getMessageSender = (message) => {
        if (message.from) return message.from;
        if (message.sender) return message.sender;
        return 'Unknown';
    };

    const recentMessages = messages.slice(-10);

    return (
        <div className="flex flex-col flex-1">
            {/* Header with Background button */}
            <div className="flex items-center justify-between p-2 bg-white border-b">
                <div className="flex space-x-2">
                    <button
                        onClick={() => setShowWallpapers(!showWallpapers)}
                        className="text-sm border rounded p-1 flex items-center hover:bg-gray-50"
                    >
                        <Image className="w-4 h-4 mr-1" />
                        Background
                    </button>
                </div>
            </div>

            {/* Wallpaper selection popup */}
            {showWallpapers && (
                <div className="absolute right-0 top-16 bg-white shadow-lg rounded-lg p-4 z-50">
                    <h3 className="font-bold mb-2">Choose Background</h3>
                    <div className="grid grid-cols-2 gap-2">
                        {WALLPAPERS.map(wp => (
                            <button
                                key={wp.id}
                                className={`p-2 rounded ${wp.class} border hover:opacity-80 transition-opacity ${
                                    wallpaper === wp.id ? 'ring-2 ring-blue-500' : ''
                                }`}
                                onClick={() => {
                                    setWallpaper(wp.id);
                                    setShowWallpapers(false);
                                }}
                            >
                                {wp.name}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {/* Messages container */}
            <div
                className={`flex-1 overflow-y-auto p-4 ${WALLPAPERS.find(w => w.id === wallpaper)?.class || ''}`}
                style={{
                    height: 'calc(100vh - 180px)',
                    scrollBehavior: 'smooth'
                }}
            >
                <div className="space-y-4 flex flex-col">
                    {Array.isArray(recentMessages) && recentMessages.length > 0 ? (
                        recentMessages.map((message, index) => {
                            const isOwnMessage = message.from === username || message.sender === username;
                            const sender = getMessageSender(message);
                            const isPrivateMessage = message.isPrivate || message.from;

                            return (
                                <div
                                    key={`${message.timestamp}-${index}`}
                                    className={`flex flex-col ${isOwnMessage ? 'items-end' : 'items-start'}`}
                                >
                                    <div className="flex items-center space-x-2 mb-1">
                                        <span className={`text-sm font-medium ${
                                            isOwnMessage ? 'text-blue-600' : 'text-gray-600'
                                        }`}>
                                            {isOwnMessage ? 'You' : sender}
                                        </span>
                                        <span className="text-xs text-gray-400">{message.time}</span>
                                        {isPrivateMessage && (
                                            <span className="text-xs text-purple-500">Private</span>
                                        )}
                                    </div>

                                    <div className={getMessageStyles(message)}>
                                        <p>{message.text}</p>
                                        {message.status && (
                                            <span className="ml-2 text-xs opacity-70">
                                                {message.status === 'sent' ? '✓' : '✓✓'}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-center text-gray-500 mt-4">
                            No messages yet. Start the conversation!
                        </div>
                    )}

                    {typingUsers?.size > 0 && (
                        <div className="text-sm text-gray-500 italic mt-2">
                            {Array.from(typingUsers).join(', ')} {typingUsers.size === 1 ? 'is' : 'are'} typing...
                        </div>
                    )}

                    <div ref={messagesEndRef} className="h-4" />
                </div>
            </div>
        </div>
    );
};

export default MessageList;