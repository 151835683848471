import React, { useState } from 'react';
import { Users, X, Ban, MessageCircle } from 'lucide-react';

export const UserSidebar = ({ onlineUsers, onUserSelect }) => {
    const [ignoredUsers, setIgnoredUsers] = useState(new Set());
    const [showUserProfile, setShowUserProfile] = useState(null);

    // Mock user profiles - in production, this would come from your backend
    const userProfiles = onlineUsers.reduce((acc, user) => {
        acc[user] = {
            username: user,
            country: localStorage.getItem('user_country_' + user) || 'Unknown',
            joinedDate: new Date().toLocaleDateString()
        };
        return acc;
    }, {});

    const handleIgnoreUser = (username) => {
        setIgnoredUsers(prev => {
            const updated = new Set(prev);
            if (updated.has(username)) {
                updated.delete(username);
            } else {
                updated.add(username);
            }
            return updated;
        });
    };

    return (
        <div className="w-64 bg-gray-50 border-l">
            <div className="p-4 border-b">
                <h2 className="font-bold flex items-center">
                    <Users className="w-4 h-4 mr-2" />
                    Online Users ({onlineUsers.length})
                </h2>
            </div>
            <div className="p-4">
                {onlineUsers.map(user => {
                    const isIgnored = ignoredUsers.has(user);
                    return (
                        <div
                            key={user}
                            className="relative group"
                        >
                            <div
                                className="flex items-center justify-between p-2 hover:bg-gray-100 rounded cursor-pointer"
                                onMouseEnter={() => setShowUserProfile(user)}
                                onMouseLeave={() => setShowUserProfile(null)}
                            >
                                <div className="flex items-center space-x-2">
                                    <div className="w-2 h-2 bg-green-500 rounded-full" />
                                    <span className={isIgnored ? 'line-through text-gray-400' : ''}>
                                        {user}
                                    </span>
                                </div>

                                <div className="hidden group-hover:flex space-x-1">
                                    {!isIgnored && (
                                        <button
                                            onClick={() => onUserSelect(user)}
                                            className="p-1 hover:bg-blue-100 rounded"
                                            title="Send message"
                                        >
                                            <MessageCircle className="w-4 h-4 text-blue-600" />
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleIgnoreUser(user)}
                                        className="p-1 hover:bg-red-100 rounded"
                                        title={isIgnored ? "Unignore user" : "Ignore user"}
                                    >
                                        {isIgnored ?
                                            <X className="w-4 h-4 text-red-600" /> :
                                            <Ban className="w-4 h-4 text-red-600" />
                                        }
                                    </button>
                                </div>
                            </div>

                            {/* User Profile Tooltip */}
                            {showUserProfile === user && (
                                <div className="absolute left-full ml-2 top-0 z-50 bg-white rounded-lg shadow-lg p-4 w-48">
                                    <div className="text-sm">
                                        <p className="font-bold">{user}</p>
                                        <p className="text-gray-600">
                                            Country: {userProfiles[user]?.country || 'Unknown'}
                                        </p>
                                        <p className="text-gray-600">
                                            Joined: {userProfiles[user]?.joinedDate || 'Today'}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};