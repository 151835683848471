import React, { useState, useEffect } from 'react';
import { UserRegistration } from './components/auth/UserRegistration';
import { ChatInterface } from './components/chat/ChatInterface';
import { initializeAnalytics, setupSEO, initializeAds, AdPlacement } from './services/analytics';

function App() {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    const [settings, setSettings] = useState(() =>
            JSON.parse(localStorage.getItem('chatSettings')) || {
                enableSound: true,
                showOnlineStatus: true,
                allowDirectMessages: true,
                allowAds: true
            }
    );

    useEffect(() => {
        initializeAnalytics();
        setupSEO();
        if (settings.allowAds) {
            initializeAds();
        }

        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
        }
    }, [settings.allowAds]);

    const handleRegistration = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const handleSettingsUpdate = (newSettings) => {
        setSettings(newSettings);
        localStorage.setItem('chatSettings', JSON.stringify(newSettings));
    };

    // If user exists, show chat interface
    if (user) {
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col">
                {/* Top banner ad */}
                {settings.allowAds && (
                    <div className="w-full border-b">
                        <AdPlacement position="banner" format="horizontal" />
                    </div>
                )}

                {/* Main chat interface */}
                <div className="flex-1">
                    <ChatInterface
                        user={user}
                        settings={settings}
                        onSettingsUpdate={handleSettingsUpdate}
                    />
                </div>

                {/* Bottom banner ad */}
                {settings.allowAds && (
                    <div className="w-full border-t">
                        <AdPlacement position="banner" format="horizontal" />
                    </div>
                )}
            </div>
        );
    }

    // Registration page with top ad only
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col">
            {settings.allowAds && (
                <div className="w-full border-b">
                    <AdPlacement position="banner" format="horizontal" />
                </div>
            )}
            <div className="flex-1 flex items-center justify-center p-4">
                <UserRegistration onRegister={handleRegistration} />
            </div>
        </div>
    );
}

export default App;