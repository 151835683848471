import { SEO_KEYWORDS, META_TAGS } from "../utils/constants";
import React from 'react';

// Analytics initialization
export const initializeAnalytics = () => {
    // Google Analytics setup
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=YOUR-GA-ID`;
    document.head.appendChild(script);

    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];

    // Define gtag function
    window.gtag = function() {
        window.dataLayer.push(arguments);
    }

    // Initialize GA
    window.gtag('js', new Date());
    window.gtag('config', 'YOUR-GA-ID');
};

// SEO setup
export const setupSEO = () => {
    const metaTags = [
        { name: 'description', content: META_TAGS.description },
        { name: 'keywords', content: SEO_KEYWORDS.join(', ') },
        { property: 'og:title', content: META_TAGS.title },
        { property: 'og:description', content: META_TAGS.ogDescription }
    ];

    metaTags.forEach(tag => {
        const meta = document.createElement('meta');
        Object.entries(tag).forEach(([key, value]) => {
            meta[key] = value;
        });
        document.head.appendChild(meta);
    });
};

// Ad providers initialization
export const initializeAds = (providers = ['google']) => {
    providers.forEach(provider => {
        switch(provider) {
            case 'google':
                const adScript = document.createElement('script');
                adScript.async = true;
                adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
                adScript.setAttribute('data-ad-client', 'YOUR-ADSENSE-ID');
                document.head.appendChild(adScript);
                break;

            case 'media.net':
                const mediaNetScript = document.createElement('script');
                mediaNetScript.async = true;
                mediaNetScript.src = '//contextual.media.net/dmedianet.js?cid=YOUR_MEDIANET_ID';
                document.head.appendChild(mediaNetScript);
                break;

            case 'propeller':
                const propellerScript = document.createElement('script');
                propellerScript.async = true;
                propellerScript.src = '//propeller-api.com/ploader.js';
                propellerScript.setAttribute('data-account', 'YOUR_PROPELLER_ID');
                document.head.appendChild(propellerScript);
                break;

            case 'criteo':
                const criteoScript = document.createElement('script');
                criteoScript.async = true;
                criteoScript.src = '//static.criteo.net/js/ld/publishertag.js';
                document.head.appendChild(criteoScript);
                break;
        }
    });
};

// Ad tracking and optimization
export const adTracker = {
    userEngagement: 0,
    sessionStartTime: new Date(),

    trackEngagement: function() {
        const now = new Date();
        this.userEngagement = (now - this.sessionStartTime) / 1000 / 60; // in minutes
        return this.userEngagement;
    },

    shouldShowAd: function() {
        return this.userEngagement > 5; // Show ads after 5 minutes of engagement
    }
};

// Ad placement component
export const AdPlacement = ({ provider = 'google', position = 'default', format = 'auto' }) => {
    const adStyles = {
        default: 'w-full my-4',
        sidebar: 'w-64 min-h-[600px] hidden lg:block',
        banner: 'w-full h-[90px]',
        native: 'w-full min-h-[250px]'
    };

    const renderAd = () => {
        switch(provider) {
            case 'google':
                return (
                    <ins className="adsbygoogle"
                         style={{ display: 'block' }}
                         data-ad-client="YOUR-ADSENSE-ID"
                         data-ad-slot="YOUR-AD-SLOT"
                         data-ad-format={format}
                         data-full-width-responsive="true">
                    </ins>
                );

            case 'media.net':
                return (
                    <div id={`media-net-ad-${Math.random().toString(36).substr(2, 9)}`}
                         className="media-net-ad" />
                );

            case 'propeller':
                return (
                    <div className="propeller-ad-unit"
                         data-prop-id="YOUR-PROPELLER-UNIT-ID" />
                );

            default:
                return null;
        }
    };

    return (
        <div className={`ad-container ${adStyles[position]}`}>
            {adTracker.shouldShowAd() && renderAd()}
        </div>
    );
};