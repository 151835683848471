export const RESTRICTED_ROOMS = ['Gaming', 'Music'];
export const NOTIFICATION_SOUND = new Audio('/notification.mp3');
export const DEFAULT_ROOMS = ['General'];
export const SEO_KEYWORDS = [
    'allindiachat', 'indiachat', 'usa chat', 'international chat',
    'free chat rooms', 'online chat', 'global chat'
];
export const META_TAGS = {
    description: 'Free online chat rooms for India, USA and global users',
    title: 'Global Chat Rooms',
    ogDescription: 'Connect with people worldwide in free chat rooms'
};